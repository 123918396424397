
import { defineComponent, onMounted, onBeforeMount } from "vue";
import ToolbarFilter from "@/components/toolbar/ToolbarFilter.vue";
import ToolbarActions from "@/components/toolbar/ToolbarActions.vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import useComponentv2 from '@/composables/v2/useComponent';
import Table from '@/components/tables/v2/Table.vue';
import Modal from "@/components/modals/default/v2/Modal.vue";
import Icon from '@/components/icons/Icon.vue';

export default defineComponent({
  name: '',
  components: {
      ToolbarFilter,
      ToolbarActions,
      Table,
      Icon,
      Modal,
  },
  props: {
    currentId: String
  },
  setup (props, { emit }) {
    const { initComponent, componentData, componentRegisterId, componentLoading, updateComponent, submitButtonComponent, submitLoadingComponent, saveComponent, componentStatus, reloadComponent, test } = useComponentv2();
    
    initComponent({
      componentName: "ShipmentsShipmentLabelsTable", 
      displayMethod: "existing", /* new or existing */
      componentType: "table", /* form or table */
      dispatchActions: { init: "LABELS", save: "LABEL_EDIT", params: { init: false, key: "", value: ""}},
      componentReload: true,
      componentRedirect: { init: false, data: "", path: ""},
      currentData: "allLabels",
      componentFilter: { init: false, get: "currentLabelsFilter" },
      componentToolbar: { init: true, filter: false, actions: { init: false, title: "Labels", menu: [ { label: "Create", target: "#es_modal_shipments_shipment_labels_create" } ] } },
      currentId: props.currentId
    });

    onMounted(() => {
      MenuComponent.reinitialization();
    })

    return {
      componentData,
      componentRegisterId,
      componentLoading,
      componentStatus,
      updateComponent,
      submitButtonComponent,
      submitLoadingComponent,
      saveComponent,
      reloadComponent,
      test
    }
  }
});
