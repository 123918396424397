<template>
   <ShipmentsShipmentLabels :current-id="currentId"/>
</template>

<script>
import { defineComponent, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useContentStore } from "@/stores/content";
import ShipmentsShipmentLabels from '@/components/ShipmentsShipmentLabels';

export default defineComponent({
  components: {
    ShipmentsShipmentLabels,
  },
  setup() {
    const route = useRoute();

    const currentId = computed(() => {
      return route.params.uid
    });

    return {
      currentId
    }

  }
});
</script>